import {PAGE_NONE} from "./constants";
import * as types from "./constants";

export const LOGIN_ERROR_NONE = 0;
export const LOGIN_ERROR_PASSWORD = 1;

const initialState = {
  // global
  wsOnline: false, // true when ws is connected
  showSpinny: false,

  // logged out
  initialLoad: true,
  showLogin: true,
  showLoginError: LOGIN_ERROR_NONE,

  // logged in
  dashboard: "", // kiosk1|kiosk2 or ""
  kiosk: null
  // kiosk: {
  //   uid: "kiosk1",
  //   state: "CHATSTATE_CHAT"
  // }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_WSONLINE:
      return {
        ...state,
        wsOnline: action.wsOnline
      };

    case types.LOGOUT_SHOWMESSAGE:
      return {
        ...initialState,
        initialLoad: false,
        showLogin: action.showLogin, // bool
        showLoginError: action.showLoginError // int
      };

    case types.SHOW_SPINNY:
      return {
        ...state,
        showSpinny: action.showSpinny
      }

    case types.SET_DASHBOARD:
      return {
        ...state,
        dashboard: action.dashboard
      }

    case types.SET_KIOSK:
      return {
        ...state,
        kiosk: action.kiosk
      }

    default:
      return state;
  }
}
