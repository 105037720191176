import {wsSend} from "./ws"
import {globalSetDashboard, globalSetKiosk, globalShowSpinny} from "../redux/globalActions";
import {startRTC, stopAllVideo, wsHandleRTCEvent} from "./rtc";
import {CHATSTATE_WATCH} from "../redux/constants";

const WS_REGISTER = "WS_SETNAME";
const WS_MESSAGE = "WS_MESSAGE";

const HeaderReset = "Reset";
const HeaderPing = "Ping";
const HeaderPong = "Pong";
const HeaderDashboardReply = "DashboardReply";
const HeaderKioskAccept = "KioskAccept";
const HeaderWebRTCEvent = "WebRTCEvent";

export function wsHandleMessage(dispatch, msg) {
  switch (msg.header) {
    case HeaderReset:
      console.log('*** Reset ***');
      stopAllVideo();
      dispatch(globalShowSpinny(false));
      dispatch(globalSetKiosk(null));
      break;
    case HeaderPing:
      // reply pong
      console.log('reply to server ping');
      wsSend({header: "Pong", payload: null});
      break;
      // TODO web client side ping
      // case HeaderPong:
      //   // reply to our ping
    case HeaderDashboardReply:
      dispatch(globalSetDashboard(msg.payload.detail));
      break;
    case HeaderKioskAccept:
      startKioskVideoStep2(dispatch, msg.payload.uid);
    case HeaderWebRTCEvent:
      wsHandleRTCEvent(msg.payload);
      break;
    default:
      console.log(`Unknown message: ${JSON.stringify(msg)}`)
  }
}

export function startKioskVideoStep1(dispatch, kioskId) {
  dispatch(globalShowSpinny(true));
  wsSend({header: "KioskRequest", payload: {uid: kioskId}});
}

export function startKioskVideoStep2(dispatch, kioskId) {
  dispatch(globalSetKiosk({uid: kioskId, state: CHATSTATE_WATCH}));
  startRTC(showSpinny => dispatch(globalShowSpinny(showSpinny)));
  wsSend({header: "StartVideo", payload: null});
}
