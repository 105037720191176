import React, {useRef} from "react"
import { connect } from "react-redux";

import {LOGIN_ERROR_NONE, LOGIN_ERROR_PASSWORD} from "../redux/globalReducers";
import {globalCreateSession} from "../redux/globalActions";

const loginErrorMsg = (code) => {
    switch (code) {
        case LOGIN_ERROR_PASSWORD:
            return "ログインIDもしくはパスワードが間違っています";
        default:
            return "";
    }
}

const Login = ({global, actionDoLogin}) => {
    const refLoginId = useRef(null);
    const refPassword = useRef(null);

    const doLogin = (e) => {
        e.preventDefault();
        let fLoginId = refLoginId.current.value;
        let fPassword = refPassword.current.value;
        refPassword.current.value = "";
        actionDoLogin(fLoginId, fPassword);
    };

    return <div className="container">
        <div className="row justify-content-center align-items-center">
            <div className="col-4">
                <br/>
                <br/>
                <br/>
                <br/>
                <h2><span className="text-primary">Otegaru net Chat</span></h2>
                <br/>
            </div>
        </div>
        <div className="row justify-content-center align-items-center">
            <div className="col-5">
                {global.showLoginError!=LOGIN_ERROR_NONE && <div className="alert alert-danger" role="alert">
                    {loginErrorMsg(global.showLoginError)}
                </div>
                }
                <div className="col">
                    <form>
                        <div className="form-group">
                            <label htmlFor="login-id">ログインID</label>
                            <input type="text" className="form-control" id="login-id" placeholder="ログインID" ref={refLoginId}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="login-password">パスワード</label>
                            <input type="password" className="form-control" id="login-password" placeholder="パスワード" ref={refPassword}/>
                        </div>
                        <button type="submit" className="btn btn-primary" onClick={(e)=>doLogin(e)}>ログイン</button>
                    </form>
                </div>
            </div>
        </div>
    </div>;
}


const mapState = state => ({
    global: state.global
});

const mapDispatch = dispatch => ({
    actionDoLogin: (user, password) => dispatch(globalCreateSession(user, password))
});

export default connect(mapState, mapDispatch)(Login);
