import React, {useState, useRef} from "react"
import { connect } from "react-redux";
import $ from 'jquery';

const Spinny = ({global}) => {
  const refModal = useRef(null);
  const [isShown, setIsShown] = useState(false);

  if(global.showSpinny != isShown) {
    $(refModal.current).modal(global.showSpinny?'show':'hide');   // {show: global.showSpinny, keyboard: false});
    setIsShown(global.showSpinny);
  }

  return <div className="modal" tabIndex="-1" data-backdrop="static" data-keyboard="false" role="dialog" ref={refModal}>
    <div className="modal-dialog modal-dialog-centered text-center" role="document">
      <span className="fa fa-spinner fa-spin fa-3x w-100"></span>
    </div>
  </div>;
}

const mapState = state => ({
  global: state.global
});

const mapDispatch = dispatch => ({
});

export default connect(mapState, mapDispatch)(Spinny);
