import store from "../redux/Store";

import {REST_SERVER, WS_PATH} from "../redux/constants";
import {globalSetDashboard, globalSetKiosk, globalSetWSOnline} from "../redux/globalActions";
import {wsHandleMessage} from "./wsActions";
import {stopAllVideo} from "./rtc";

// global web socket (but only used here?)
export var ws = null;

const wsOnOpen = (e) => {
    console.log('ws.onopen');
    store.dispatch(globalSetWSOnline(true));
    wsSend({header: "DashboardGet", payload: null});
}
const wsOnMessage = (e) => {
    console.log('ws.onmessage: '+e.data);
    let msg = JSON.parse(e.data);
    wsHandleMessage(store.dispatch, msg);
}
const wsOnClose = (retryFunc) => {
    console.log('ws.onclose');
    wsCleanup(retryFunc);
}
const wsOnError = (retryFunc) => {
    console.log('ws.onerror');
    wsStop(retryFunc);
}

export function wsStart(retryFunc) {
    console.log(`wsStart. ws!==null:${ws!==null} retryFunc!==null:${retryFunc!==null}`);
    if(ws!=null) return;

    let uri = REST_SERVER;
    if (uri === "") {
        // use browser location
        let loc = window.location
        uri = loc.protocol === "https:" ? "wss://" : "ws://";
        uri += loc.host; // includes port
    } else {
        // convert http/https to wss/ws
        uri = `ws${uri.substring(4)}`;
    }
    uri += WS_PATH;

    ws = new WebSocket(uri);
    ws.onopen = wsOnOpen;
    ws.onclose = _ => wsOnClose(retryFunc);
    ws.onerror = _ => wsOnError(retryFunc);
    ws.onmessage = wsOnMessage;
}

export function wsSend(msg) {
    if(ws!=null && ws.readyState==WebSocket.OPEN) {
        ws.send(JSON.stringify(msg));
    }
}

export function wsStop(retryFunc) {
    ws.close();
    wsCleanup(retryFunc);
}

function wsCleanup(retryFunc) {
    ws = null;
    stopAllVideo();
    store.dispatch(globalSetWSOnline(false));
    store.dispatch(globalSetDashboard(""));
    store.dispatch(globalSetKiosk(null));

    if(retryFunc!==null) retryFunc();
}
