import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware, combineReducers } from "redux";
import global from "./globalReducers";

const store = createStore(
    combineReducers({global}),
    applyMiddleware(
        thunkMiddleware // lets us dispatch() functions
    )
);

export default store;
