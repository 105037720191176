import React, {useEffect, useRef} from 'react';
import {connect} from "react-redux";
import {wsSend, wsStart, wsStop} from "../comms/ws";
import {startRTC, startUserVideo, stopAllVideo} from "../comms/rtc";
import {globalSetKiosk} from "../redux/globalActions";
import {CHATSTATE_CHAT, CHATSTATE_WATCH} from "../redux/constants";
import {startKioskVideoStep1} from "../comms/wsActions";

/*
 List of all kiosk and web-ui associations

 */
const Dashboard = ({global, actionGetDashboard, actionStartKioskVideo, actionStartUserVideo, actionStopAllVideo}) => {
  const refVideoKiosk = useRef(null);
  const refVideoUser = useRef(null);

  useEffect(() => {
    let retryTimeout = null;
    let retryFunc = _ => {
      if(retryTimeout!=null) clearTimeout(retryTimeout);
      if(retryFunc!=null) {
        retryTimeout = setTimeout(
          _ => wsStart(retryFunc),
          3000
        );
      }
    };
    wsStart(retryFunc);
    return () => {
      retryFunc = null; // just in case?
      if(retryTimeout!=null) {clearTimeout(retryTimeout); retryTimeout=null;}
      wsStop(null);
    };
  }, []);

  const doStartKioskVideo = (e, kioskId) => {
    e.preventDefault();
    actionStartKioskVideo(kioskId);
  }

  return <div className="container-fluid m-4">
    {!global.wsOnline && <div className="row mb-4">
        <div className="col">
          <span className="text-info">ネットワークエラ。再接続します…</span>
        </div>
      </div>
    }

    {/* show list of known kiosks*/}
    {global.wsOnline && global.kiosk == null && <React.Fragment>
      <div className="row mb-4">
        <div className="col">
          <button type="submit" className="btn btn-primary"
                  onClick={(e) => actionGetDashboard()}>おてがるクリーンの表示を更新する
          </button>
        </div>
      </div>
      <div className="row mb-4">
        {global.dashboard != "" && global.dashboard.split("|").map(kioskId => {
          let kioskLabel = kioskId.slice(-4);
          if (kioskLabel == "f41c") kioskLabel = "JP Comm 受付"
          else if (kioskLabel == "7926") kioskLabel = "ミズカミ 001"
          else if (kioskLabel == "51be") kioskLabel = "Tablet NH-G101"
          else if (kioskLabel == "d9e5") kioskLabel = "Tablet - D00004"
          else if (kioskLabel == "ac2c") kioskLabel = "Dev - Melb"
          else if (kioskLabel == "10b2") kioskLabel = "おてがるクリーン Demo"
          else kioskLabel = "ID: " + kioskLabel;

          return <div key={`col-${kioskId}`} className="col">
            <button type="submit" className="btn btn-primary ml-4"
                    onClick={(e) => doStartKioskVideo(e, kioskId)}>{kioskLabel}
            </button>
          </div>
        })}
      </div>
    </React.Fragment>
    }

    {/*or show video chat*/}
    {global.wsOnline && global.kiosk != null && <React.Fragment>
      <div className="row mb-4">
        <div className="col">
          {global.showSpinny && <div className="spinner-border"/>}
          {global.kiosk.state == CHATSTATE_WATCH && <button type="submit" className="btn btn-primary"
                                                            onClick={(e) => actionStartUserVideo()}>チャット開始
          </button>
          }
          <button type="submit" className="btn btn-primary ml-4"
                  onClick={(e) => actionStopAllVideo()}>チャット終了
          </button>
        </div>
      </div>
    </React.Fragment>
    }

    <div className="row mb-4">
      <div className="col-9">
        <video id="videoKiosk" ref={refVideoKiosk} playsInline autoPlay width="500px" height="500px"
               style={{transform: "rotate(180deg)"}}></video>
      </div>
      <div className="col-3">
        <video id="videoUser" ref={refVideoUser} playsInline autoPlay width="150px" height="150px"></video>
      </div>
    </div>
  </div>;
}

const mapState = state => ({
  global: state.global
});

const mapDispatch = dispatch => ({
  actionGetDashboard: _ => wsSend({header: "DashboardGet", payload: null}),
  actionStartKioskVideo: (kioskId) => startKioskVideoStep1(dispatch, kioskId),
  actionStartUserVideo: (kioskId) => {
    startUserVideo();
    dispatch(globalSetKiosk({uid: kioskId, state: CHATSTATE_CHAT}));
  },
  actionStopAllVideo: _ => {
    wsSend({header: "StopVideo", payload: null});
    stopAllVideo();
    dispatch(globalSetKiosk(null));
  }
});

export default connect(mapState, mapDispatch)(Dashboard);
