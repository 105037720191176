import {BITRATE_MAX_AUDIO, BITRATE_MAX_VIDEO, ConfigRTCConnection} from "../redux/constants";
import {wsSend} from "./ws";

export var rtcConnection = null;
export var rtcStreamKiosk = null;

// https://blog.mozilla.org/webrtc/perfect-negotiation-in-webrtc/


// const streamRemote = new MediaStream();
// //   document.getElementById('videoRemote').srcObject = streamRemote;
//
// rtcConnectionLocal.addEventListener('track', async (event) => {
//     if(streamRemote.getTracks().length==0) {
//         document.getElementById('videoRemote').srcObject = streamRemote;
//     }
//     streamRemote.addTrack(event.track, streamRemote);


export function startRTC(dispatchShowSpinny) {
  rtcConnection = new RTCPeerConnection(ConfigRTCConnection);
  rtcConnection.addEventListener("negotiationneeded", e => rtcNegotiationNeeded(e), false);
  rtcConnection.addEventListener("icecandidate", e => rtcICECandidate(e), false);
  rtcConnection.addEventListener("track", e => rtcTrack(e, dispatchShowSpinny), false);
}

export function startUserVideo() {
  // connect camera
  navigator.mediaDevices.getUserMedia({
    audio: {
      googNoiseSuppression: true,
      googEchoCancellation: true,
      echoCancellation: true,
      noiseSuppression: true,
      autoGainControl: false
    },
    video: {
      mandatory: {
        maxWidth: "640",
        maxHeight: "360",
        maxFrameRate: "30"
      }
    }
  })
    .then((stream) => {
      // show local pip
      let pipStream = new MediaStream();
      document.getElementById('videoUser').srcObject = pipStream;
      // send to kiosk
      stream.getTracks().forEach(track => {
        if (track.kind == "video") {
          pipStream.addTrack(track);
        }
        rtcConnection.addTrack(track);
        console.log(`addTrack: ${track.label}`);
      });
    });

}

// stop all video and rtc
export function stopAllVideo() {
  if(rtcConnection!=null) {
    rtcConnection.close();
    rtcConnection = null;
  }
  let domVideoUser = document.getElementById('videoUser');
  if (domVideoUser.srcObject != null) {
    domVideoUser.srcObject.getTracks().forEach(track => track.stop());
    domVideoUser.srcObject = null;
  }
  document.getElementById('videoKiosk').srcObject = null;
  rtcStreamKiosk = null;
}

function wsSendRTCEvent(session, ice) {
  wsSend({
    header: "WebRTCEvent",
    payload: {
      session: session,
      ice: ice
    }
  });
}

export function wsHandleRTCEvent(rtcEvent) {
  if (rtcEvent.session != null) {
    handleRemoteSession(rtcEvent.session);
  }
  if (rtcEvent.ice != null) {
    handleRemoteICE(rtcEvent.ice);
  }
}

function rtcError(err) {
  console.log("WebRTC Error: " + err);
}

function rtcTrack(e, dispatchShowSpinny) {
  console.log(`rtcTrack ${e.track.label}`);
  if (rtcStreamKiosk == null) {
    dispatchShowSpinny(false);

    // default 180 for backward compat with "webrtc" id
    setKioskVideoRotation(e.streams[0].id == "kiosk-landscape" ? 0 : 180);
    rtcStreamKiosk = new MediaStream();
    document.getElementById('videoKiosk').srcObject = rtcStreamKiosk;
  }

  rtcStreamKiosk.addTrack(e.track);
  // document.getElementById('videoKiosk').srcObject = e.streams[0];
}

function setKioskVideoRotation(deg) {
  document.getElementById('videoKiosk').style.setProperty('transform', `rotate(${deg}deg)`);
}

// https://webrtchacks.com/limit-webrtc-bandwidth-sdp/
function setMediaBitrate(sdp, media, bitrate) {
  var lines = sdp.split("\n");
  var line = -1;
  for (var i = 0; i < lines.length; i++) {
    if (lines[i].indexOf("m=" + media) === 0) {
      line = i;
      break;
    }
  }
  if (line === -1) {
    console.debug("Could not find the m line for", media);
    return sdp;
  }
  console.debug("Found the m line for", media, "at line", line);

  // Pass the m line
  line++;

  // Skip i and c lines
  while (lines[line].indexOf("i=") === 0 || lines[line].indexOf("c=") === 0) {
    line++;
  }

  // If we're on a b line, replace it
  if (lines[line].indexOf("b") === 0) {
    console.debug("Replaced b line at line", line);
    lines[line] = "b=AS:" + bitrate + "\r";
    return lines.join("\n");
  }

  // Add a new b line
  console.debug("Adding new b line before line", line);
  var newLines = lines.slice(0, line)
  newLines.push("b=AS:" + bitrate + "\r")
  newLines = newLines.concat(lines.slice(line, lines.length))
  return newLines.join("\n")
}

// kiosk initiate
function rtcNegotiationNeeded(e) {
  console.log(`rtcNegotiationNeeded: ${JSON.stringify(e)}`);
  rtcConnection.createOffer()
    .then((offer) => {
      offer.sdp = setMediaBitrate(
        setMediaBitrate(offer.sdp, "audio", BITRATE_MAX_AUDIO),
        "video",
        BITRATE_MAX_VIDEO
      );
      return rtcConnection.setLocalDescription(offer);
    })
    .then(() => {
      wsSendRTCEvent(rtcConnection.localDescription, null);
    })
    .catch(err => rtcError(err));
}

function rtcICECandidate(e) {
  wsSendRTCEvent(
    null,
    e.candidate != null // null indicates ice negotiation finished
      ? e.candidate
      : {candidate: '', sdpMid: '', sdpMLineIndex: 0}
  );
}

function handleRemoteICE(ice) {
  console.log(`handleRemoteICE: ${JSON.stringify(ice)}`);
  rtcConnection.addIceCandidate(ice)
    .catch(err => rtcError(err));
}

function handleRemoteSession(session) {
  console.log(`handleRemoteSession: ${JSON.stringify(session)}`);
  const sd = new RTCSessionDescription(session);
  rtcConnection.setRemoteDescription(sd)
    .then(() => {
      if (sd.type == 'offer') {
        return rtcConnection.createAnswer()
          .then((answer) => {
            return rtcConnection.setLocalDescription(answer);
          })
          .then(() => {
            wsSendRTCEvent(rtcConnection.localDescription, null);
          })
          .catch(err => rtcError(err));
      }
    })
    .catch(err => rtcError(err));
}
