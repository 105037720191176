import axios from 'axios';
import * as types from "./constants";
import {LOGIN_ERROR_NONE, LOGIN_ERROR_PASSWORD} from "./globalReducers";
import {REST_SERVER} from "./constants";

export function globalSetWSOnline(wsOnline) {
  return {
    type: types.SET_WSONLINE,
    wsOnline: wsOnline
  }
}

export function globalLogoutSetShowMessage(showLogin, showLoginError) {
  return {
    type: types.LOGOUT_SHOWMESSAGE,
    showLogin: showLogin,
    showLoginError: showLoginError
  }
}

export function globalShowSpinny(showSpinny) {
  return {
    type: types.SHOW_SPINNY,
    showSpinny: showSpinny
  }
}

export function globalSetDashboard(dashboard) {
  return {
    type: types.SET_DASHBOARD,
    dashboard: dashboard
  }
}

export function globalSetKiosk(kiosk) {
  return {
    type: types.SET_KIOSK,
    kiosk: kiosk
  }
}

// on app load (one time)
export function globalCheckSession() {
  return function (dispatch) {
    dispatch(globalShowSpinny(true));
    return axios({
      method: 'GET',
      url: `${REST_SERVER}/session`
    })
      .then(r => r.data)
      .then(json => {
        sessionOk(dispatch)
      })
      .catch(err => {
        dispatch(globalLogoutSetShowMessage(
          true,
          // don't show password error message first time
          LOGIN_ERROR_NONE
        ));
      })
      .finally(_ => {
        dispatch(globalShowSpinny(false))
      })
  }
}

export function globalCreateSession(user, password) {
  console.log(`u:${user}`);
  return function (dispatch) {
    dispatch(globalShowSpinny(true));
    return axios({
      method: 'POST',
      url: `${REST_SERVER}/session`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: `user=${encodeURIComponent(user)}&password=${encodeURIComponent(password)}`
    })
      .then(r => r.data)
      .then(json => {
        sessionOk(dispatch)
      })
      .catch(err => {
        dispatch(globalLogoutSetShowMessage(
          true,
          LOGIN_ERROR_PASSWORD /* err.response.status */
        ));
      })
      .finally(_ => {
        dispatch(globalShowSpinny(false))
      })
  }
}

function sessionOk(dispatch) {
  dispatch(globalLogoutSetShowMessage(false, LOGIN_ERROR_NONE));
  dispatch(globalSetDashboard(""));
}
