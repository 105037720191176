export const SET_WSONLINE = "SET_WSONLINE";

export const LOGOUT_SHOWMESSAGE = "LOGOUT_SHOWMESSAGE";
export const SHOW_SPINNY = "SHOW_SPINNY";

export const SET_KIOSK = "SET_KIOSK";
export const SET_DASHBOARD = "SET_DASHBOARD";

export const CHATSTATE_WATCH = "CHATSTATE_WATCH";
export const CHATSTATE_CHAT = "CHATSTATE_CHAT";

// REACT_APP_REST_SERVER=http://localhost:8080 npm start
// REACT_APP_REST_SERVER=https://chat.otgr.jp npm start

export const REST_SERVER = process.env.REACT_APP_REST_SERVER||"";

export const WS_PATH = "/user";

export const BITRATE_MAX_AUDIO = 50;
export const BITRATE_MAX_VIDEO = 500;

export const ConfigRTCConnection = {
    iceServers: [
        // {url: 'stun:stun.l.google.com:19302'},
        {url: 'turn:203.216.141.145:3478?transport=udp', username: "otegaru", credential: "9a217a12"},
        {url: 'turn:203.216.141.145:3478?transport=tcp', username: "otegaru", credential: "9a217a12"}
    ]
};
