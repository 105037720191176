import React, {useEffect} from 'react';
import {connect, Provider} from "react-redux";

import store from "./redux/Store";
import Login from "./components/Login"
import Dashboard from "./components/Dashboard";
import Spinny from "./components/Spinny";
import {globalCheckSession} from "./redux/globalActions";

const mapState = state => ({
    global: state.global
});
const mapDispatch = dispatch => ({
  actionCheckSession: () => dispatch(globalCheckSession())
});

const ChooserComponent = ({global, actionCheckSession}) => {
    useEffect(() => {
      actionCheckSession();
    }, []);

    if(global.initialLoad) {
      // wait for useEffect() check session
      return null;
    } else if (global.showLogin) {
      return <Login />
    } else {
      return <Dashboard/>
    }
};
const Chooser = connect(mapState, mapDispatch)(ChooserComponent);

const App = () => {
  return (
      <Provider store={store}>
        <Spinny/>
        <Chooser/>
      </Provider>
  );
}

export default App;
